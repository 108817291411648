import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";

import DefaultImage from "@images/OG.png"

const JsonLd = ({
  type,
  title,
  description,
  detail
}) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);

  const {
    siteUrl,
  } = site.siteMetadata;

  let jsonLdStructuredData;
  switch(type) {
    case 'blog':
      jsonLdStructuredData = [
        {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "TOP",
              "item": "https://place.cuddle-jp.com/"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "コラム",
              "item": "https://place.cuddle-jp.com/blogs/"
            },
            {
              "@type": "ListItem",
              "position": 3,
              "name": title,
              "item": `${siteUrl}${pathname}`
            }
          ]
        },
        {
          "@context": "https://schema.org/",
          "@type": "Article",
          mainEntityOfPage: {
            "@type": "WebPage",
            "@id": `${siteUrl}/blog/`,
          },
          author: {
            "@type": "Person",
            name: detail.author.name,
            image: detail.author.image.url,
          },
          publisher: {
            "@type": "Organization",
            name: "Cuddle Place（カドル プレイス）",
            url: siteUrl,
            logo: {
                "@type": "ImageObject",
                url: "https://place.cuddle-jp.com/images/icon.png",
                width: 300,
                height: 300
            }
          },
          name: title,
          headline: title,
          image: detail.hero_image ? detail.hero_image.url : DefaultImage,
          url: `${siteUrl}${pathname}`,
          description: description,
          datePublished: detail.publishedAt,
          dateCreated: detail.publishedAt,
          dateModified: detail.revisedAt,
        }
      ]
      break;
    case 'event':
      jsonLdStructuredData = [
        {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "TOP",
              "item": "https://place.cuddle-jp.com/"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "既婚者合コン",
              "item": "https://place.cuddle-jp.com/parties/"
            },
            {
              "@type": "ListItem",
              "position": 3,
              "name": title,
              "item": `${siteUrl}${pathname}`
            }
          ]
        },
        {
          "@context": "https://schema.org",
          "@type": "Event",
          "name": title,
          "startDate": `${detail.date}T${detail.startAt}`,
          "endDate": `${detail.date}T${detail.endAt}`,
          "eventAttendanceMode": "https://schema.org/OfflineEventAttendanceMode",
          "eventStatus": "https://schema.org/EventScheduled",
          "location": {
            "@type": "Place",
            "name": detail.location? detail.location:detail.area,
          },
          "image": detail.image ? detail.image : DefaultImage,
          "description": description,
          "performer": {
            "@type": "PerformingGroup",
            "name": detail.serviceName
          },
          "organizer": {
            "@type": "Organization",
            "name": "Cuddle Place（カドル プレイス）",
            url: siteUrl,
            logo: {
                "@type": "ImageObject",
                url: "https://place.cuddle-jp.com/images/icon.png",
                width: 300,
                height: 300
            }
          }
        }
      ]
      default:
        jsonLdStructuredData = {
          "@context": "https://schema.org",
          "@type": "Organization",
          "logo": "https://place.cuddle-jp.com/images/icon.png",
          "name": "既婚者向け総合メディア - Cuddle Place | 既婚者合コン・マッチングアプリ",
          "url": "https://place.cuddle-jp.com/",
          "description": "「Cuddle(カドル)」は結婚後に異性と交流したい方を応援する、既婚者専用マッチングアプリです。既婚者同士お互いを理解しあえる理想の関係を創り出せます。セカンドパートナーやオープンマリッジ、婚外恋愛など、既婚者による新しい形での出会いが広まっています。",
        }
      break;
  }

  return (
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(jsonLdStructuredData)}</script>
      </Helmet>
  );
};

export default JsonLd;

const query = graphql`
  query Metadata {
    site {
      siteMetadata {
        siteUrl: siteUrl
      }
    }
  }
`